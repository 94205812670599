'use client';

import styled from 'styled-components';

type Props = {
  maxWidth?: number;
  width?: string;
};

export const ResponsiveContainer = styled.div<Props>`
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '78rem')};
  width: ${(props) => props.width ?? '100%'};
  margin: 0 auto;
  overflow: hidden;
`;
