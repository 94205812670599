'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ComponentProps } from 'react';

interface Props extends ComponentProps<typeof Link> {
  base?: string;
  href: string;
}

/**
 * Background: experiments are organized within folders. In lower environments,
 * they can be accessed via a path that includes the folder name,
 * e.g. /experiments/some-experiment. In production, if the experiment is active,
 * the folder name is not included in the path. This component creates Links that
 * work in either case.
 *
 * @param base The base path of the experiment
 * @param href The path within the experiment to link to
 * @param children The content of the link
 * @param rest Other props
 */
export const BaseLink = ({ base = '', href, children, ...rest }: Props) => {
  const pathname = usePathname();

  const finalHref = (pathname ?? '').startsWith(base) ? base + href : href;

  // add active to classes if the current path includes the href
  if ((pathname ?? '').includes(href)) {
    rest.className = `${rest.className ?? ''} active`;
  }

  return (
    <Link href={finalHref} {...rest}>
      {children}
    </Link>
  );
};
