'use client';

import {
  desktopBreakpoint,
  mobileBreakpoint,
  tabletBreakpoint,
} from '@thrivent/midwest-shared';
import { useEffect, useState } from 'react';

type Breakpoint = 'mobile' | 'tablet' | 'desktop' | 'wide';

export const useBreakpoint = (): Breakpoint => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>('desktop');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= mobileBreakpoint) {
        setBreakpoint('mobile');
      } else if (window.innerWidth <= tabletBreakpoint) {
        setBreakpoint('tablet');
      } else if (window.innerWidth <= desktopBreakpoint) {
        setBreakpoint('desktop');
      } else {
        setBreakpoint('wide');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
};

export default useBreakpoint;
