'use client';

import { BaseLink } from '../BaseLink';
import { StyledLogo } from '../Logo';
import { breakpoints } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

export const NavIcon = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 1rem;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

export const MenuHeader = styled.div`
  padding: 0.375rem 1rem;

  display: flex;
  justify-content: space-between;

  > a {
    display: flex;
    align-items: center;
  }

  ${StyledLogo} {
    width: 84px;

    ${breakpoints.desktop} {
      width: auto;
    }
  }
`;

export const MenuBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

export const MenuFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem;

  button {
    display: block;
    flex: 1;
  }
`;

export const NavListItem = styled(BaseLink)`
  font-family: ${(p) => p.theme.font.primary};
  font-size: 2rem;
  font-weight: 400;
  color: ${(p) => p.theme.midwestColors.componentButtonColorTextSubtlePrimary};
  text-align: center;

  background: transparent;
  border-bottom: 1px solid
    ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak};

  padding-block: 2rem;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${(p) => p.theme.midwestColors.identityBrandPrimaryWeak};
  }
`;

export const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  background: ${(p) => p.theme.midwestColors.white};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
`;
