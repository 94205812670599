import { getBackgroundColor } from './buttonStyles/backgroundColors';
import { ReactComponent as SpinnerIcon } from './spinnerIcon.svg';
import { ButtonVariant, ButtonWeight } from './types';
import { capitalizeFirstLetter } from '@midwest/web/base';
import { MidwestColors } from '@thrivent/midwest-shared';
import styled, { css } from 'styled-components';

interface LoaderProps {
  variant: ButtonVariant;
  weight: ButtonWeight;
}

type BackgroundColorKeys =
  | 'primaryStrongBg'
  | 'primarySubtleBg'
  | 'primaryGhostBg'
  | 'secondaryStrongBg'
  | 'secondarySubtleBg'
  | 'secondaryGhostBg'
  | 'destructiveStrongBg'
  | 'destructiveSubtleBg'
  | 'destructiveGhostBg';

/* this transition must stay in sync between button and loader to avoid visual artifacts */
export const loadingTransition = css`
  @media (prefers-reduced-motion: no-preference) {
    transition: all 0.2s ease-in-out;
  }
`;

const LoaderContainer = styled.div<{
  bgColor: BackgroundColorKeys;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${(p) => p.theme.radius.buttonRadius};
  overflow: hidden;
  background-color: ${(p) =>
    getBackgroundColor(p.theme.midwestColors as MidwestColors)[p.bgColor]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonSpinnerIcon = styled(SpinnerIcon || '')`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  height: ${(p) => p.theme.spacing.xlg};
  width: ${(p) => p.theme.spacing.xlg};
  position: absolute;
  animation: spin 1s linear infinite;
`;

/**
 * Covers the whole button with a loading spinner and background color
 */
export const Loader = ({ variant, weight }: LoaderProps) => {
  // Gets the background color for the overlay from the color definition in the styles file
  const bgColor =
    `${variant}${capitalizeFirstLetter(weight)}Bg` as BackgroundColorKeys;

  return (
    <LoaderContainer
      bgColor={bgColor}
      role="alert"
      aria-busy="true"
      aria-label="loading"
      data-testid="spinner"
    >
      <ButtonSpinnerIcon />
    </LoaderContainer>
  );
};
