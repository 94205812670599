'use client';

import { ButtonProps, MDSButton } from '@midwest/web/button';
import { useAuth } from '@thrivent-web/authentication';
import { useCallback } from 'react';
import styled from 'styled-components';

export const StyledButton = styled(MDSButton)`
  background: transparent;
  border: 1px solid
    ${(p) =>
      p.theme.midwestColors.componentButtonColorBorderSubtlePrimaryDefault};
  color: ${(p) => p.theme.midwestColors.componentButtonColorTextSubtlePrimary};

  &:hover,
  &:focus {
    color: ${(p) => p.theme.midwestColors.white};
  }
`;

export const HeaderLoginButton = ({
  ...rest
}: Omit<ButtonProps, 'children'>) => {
  const { signinRedirect, signoutSilent, isAuthenticated } = useAuth();

  const logInOrOut = useCallback(async () => {
    if (isAuthenticated) {
      await signoutSilent();
    } else {
      await signinRedirect();
    }
  }, [isAuthenticated, signinRedirect, signoutSilent]);

  return (
    <StyledButton
      onClick={logInOrOut}
      data-analytics-id={isAuthenticated ? 'log-out' : 'log-in'}
      {...rest}
    >
      {isAuthenticated ? 'Log out' : 'Log in'}
    </StyledButton>
  );
};
