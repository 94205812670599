'use client';

import LogoBrokerCheckByFinra from './LogoBrokerCheckByFinra';
import styled, { css } from 'styled-components';

export const FooterStyles = {
  Root: styled.footer`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 0;
    background-color: ${(p) => p.theme.midwestColors.white};
    color: ${(p) => p.theme.midwestColors.textPrimary};
    @media (min-width: 1280px) {
      gap: 2.5rem /* 40px */;
      padding: 4rem 0;
    }
  `,
  LogoLink: styled.a`
    display: block;
    width: 110px;
    svg {
      width: 100%;
      height: auto;
    }
    @media screen and (min-width: 1280px) {
      width: 165px;
    }
  `,
  Divider: styled.div`
    height: 1px;
    background-color: ${(p) => p.theme.midwestColors.semanticColorShadowStrong};
  `,
};

export const LinkStyles = css`
  text-decoration: none;
  color: ${(p) => p.theme.midwestColors.textPrimary};
  font-size: 1em;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const NavigationStyles = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media screen and (min-width: 1280px) {
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  `,
  Title: styled.div`
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
    font-weight: 700;
    margin-bottom: 1rem /* 16px */;
    color: ${(p) => p.theme.midwestColors.textPrimary};
  `,
  List: styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0;
    padding: 0;
  `,
  Anchor: styled.a`
    ${LinkStyles}
  `,
  Button: styled.button`
    ${LinkStyles}
    font-family: Inter, sans-serif;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  `,
  ListFollowUs: styled.div`
    @media screen and (min-width: 1280px) {
      grid-column-start: 2;
      grid-row-start: 2;
      /* Special number to be aligned to text line */
      margin-top: -7.25rem;
    }
  `,
  IconList: styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin: 0;
    padding: 0;
  `,
  Icon: styled.svg`
    font-size: 2rem;
    line-height: 2.5rem;
    align-self: center;
    fill: ${(p) => p.theme.midwestColors.textSecondary};
    &:hover,
    &:focus {
      color: ${(p) => p.theme.midwestColors.textPrimary};
    }
  `,
  SocialAnchor: styled.a`
    display: block;
    color: ${(p) => p.theme.midwestColors.textPrimary};
  `,
  SpacerCol: styled.div`
    display: none;
    @media screen and (min-width: 1280px) {
      display: block;
    }
  `,
  PhoneWrapper: styled.div`
    margin-top: 0.75rem;
  `,
  PhoneAnchor: styled.a`
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${(p) => p.theme.midwestColors.textPrimary};
    &,
    &:hover,
    &:focus {
      text-decoration: none;
    }
  `,
  AddressBlock: styled.div`
    margin-top: 1rem;
  `,
};

export const DisclaimerStyles = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
    @media screen and (min-width: 1280px) {
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  ColA: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (min-width: 1280px) {
      width: 48rem;
    }
  `,
  Copyright: styled.div``,
  DisclaimerBlock: styled.p`
    &:first-of-type {
      margin-top: 2rem;
    }
  `,
  Anchor: styled.a`
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
    color: ${(p) => p.theme.midwestColors.textSecondary};
    text-decoration: underline;

    &:hover,
    &:focus {
      color: ${(p) => p.theme.midwestColors.semanticColorSentimentInfoStrong};
    }
  `,
  ColB: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 15rem;
    @media screen and (min-width: 1280px) {
      text-align: right;
    }
  `,
  BrokerWrapper: styled.div`
    @media screen and (min-width: 1280px) {
      align-self: flex-end;
    }
  `,
  BrokerAnchor: styled.a`
    display: block;
    width: 139px;
  `,
  BrokerLogo: styled(LogoBrokerCheckByFinra)`
    width: 100%;
    height: auto;
    fill: ${(p) => p.theme.midwestColors.semanticColorShadowStrong};
  `,
};

// --------------------------------------------------------------------------

export const containerWidthRem = 78;
export const containerWidthPixels = 1248;

export const containerPaddingRem = 1;
export const containerPaddingPixels = 16;

export const containerWidthWithPaddingPixels =
  containerWidthPixels + containerPaddingPixels;

export const sectionContainerBreakpoint = `@media screen and (min-width: ${containerWidthPixels}px)`;
export const sectionContainerWithPaddingBreakpoint = `@media screen and (min-width: ${containerWidthWithPaddingPixels}px)`;

// --------------------------------------------------------------------------

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${containerWidthRem}rem;
  padding-inline: ${containerPaddingRem}rem;
  margin-inline: auto;
`;
